import React from "react";
import Navbar from "./components/Navbar";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Projects from "./pages/projects/Projects";

const App = () => {
  return (
    <React.Fragment>
      <Navbar />
      <Projects />
      <About />
      <Contact />
    </React.Fragment>
  );
};

export default App;
