import ProfilePhoto from "./../../assets/angelika_profile.jpg";

const About = () => {
  return (
    <div className="flex flex-col-reverse gap-10 p-10 pb-10 lg:gap-20 md:flex-row lg:pt-32">
      <div className="flex flex-col gap-5 md:w-2/4 lg:w-2/3 lg:pl-72">
        <p className=" paragraph">
          I am an Architect Engineer, workshop coordinator and modelmaker.
        </p>
        <p className=" paragraph">
          I have experience in leading multicultural teams, Dutch residential
          BIM projects, handling deadlines while keeping my smile and ensuring a
          smooth modelmaking workshop flow.
        </p>
        <p className=" paragraph">
          I am that kind of person who enters the office smiling every morning
          and leaves smiling and content.{" "}
        </p>
      </div>
      <div className="self-center md:w-2/4 lg:w-1/3">
        <img src={ProfilePhoto} alt="Angelika" />
      </div>
    </div>
  );
};

export default About;
