import Instagram from "../../assets/social_icons/Instagram";
import LinkedIn from "../../assets/social_icons/LinkedIn";

const Contact = () => {
  return (
    <div className="z-10 flex flex-col justify-between gap-10 px-5 py-10 lg:py-12 lg:gap-5 lg:px-12 2xl:py-14 bg-footer-orange lg:flex-row-reverse">
      <div className="flex flex-col justify-start md:w-1/3">
        <p className="self-end paragraph">Angelika currently resides</p>
        <p className="self-end paragraph">in Rotterdam, NL</p>
      </div>
      <div className="flex flex-col justify-start gap-5 md:w-2/3">
        <h3>Contact</h3>
        <div className="line"></div>
        <ol className="flex flex-col gap-2">
          <p className="self-center paragraph--contact">
            say hello at{" "}
            <span className="self-center font-semibold">
              angelika.chantzopoulou [at] gmail [dot] com
            </span>
          </p>
          <p className="self-center paragraph--contact">
            have a look at my professional experience in my{" "}
            <a
              href="https://www.linkedin.com/in/angelika-chantzopoulou/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </p>
        </ol>
      </div>
      <div className="flex flex-col justify-end gap-5 md:w-1/3">
        <div className="flex flex-row gap-3">
          <a
            href="https://www.linkedin.com/in/angelika-chantzopoulou/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedIn />
          </a>
          <a
            href="https://www.instagram.com/akylina_loif/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram />
          </a>
        </div>
        <p className="paragraph--small">
          @ 2022 - Made by{" "}
          <a
            href="https://www.petros-chantzopoulos.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Petros
          </a>
        </p>
      </div>
    </div>
  );
};

export default Contact;
