export interface ProjectProperties {
  index: number;
  title: string;
  type: string;
  photo: any;
  photoAlt: string;
  source: string;
}

const ProjectData = [
  {
    index: 1,
    topic: "Architecture",
    title: "The Bow / Orange Architects, NL",
    photo: "./project_photos/project01.jpg",
    photoAlt: "The Bow / Orange Architects, NL",
    source: "https://orangearchitects.nl/projects/the-bow/",
  },
  {
    index: 2,
    topic: "Architecture",
    title: "De Zwaan / Orange Architects, NL",
    photo: "./project_photos/project02.jpg",
    photoAlt: "De Zwaan / Orange Architects, NL",
    source: "https://orangearchitects.nl/projects/de-zwaan/",
  },
  {
    index: 3,
    topic: "Modelmaking",
    title: "De Zwaan  / Orange Architects, NL",
    photo: "./project_photos/project03.jpg",
    photoAlt: "De Zwaan / Orange Architects, NL",
    source: "https://orangearchitects.nl/innovations/create/",
  },
  {
    index: 4,
    topic: "Architecture",
    title: "Beaufort / Orange Architects, NL",
    photo: "./project_photos/project04.jpg",
    photoAlt: "Beaufort / Orange Architects, NL",
    source: "https://orangearchitects.nl/projects/beaufort/",
  },
  {
    index: 5,
    topic: "Architecture",
    title: "De Schipper / Orange Architects, NL",
    photo: "./project_photos/project05.jpg",
    photoAlt: "De Schipper / Orange Architects, NL",
    source: "https://orangearchitects.nl/projects/de-schipper/",
  },
  {
    index: 6,
    topic: "3D Printing",
    title: " / Orange Architects, NL",
    photo: "./project_photos/project06.jpg",
    photoAlt: " / Orange Architects, NL",
    source: "https://orangearchitects.nl/innovations/create/",
  },
  {
    index: 7,
    topic: "Workshop Tutoring",
    title: "/ Atelier La Juntana",
    photo: "./project_photos/project08.jpg",
    photoAlt: "/ Atelie La Juntana",
    source: "http://www.atelierlajuntana.com/",
  },
];

export default ProjectData;
