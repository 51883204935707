const Navbar = () => {
  return (
    <div className="fixed flex flex-row justify-between w-full p-8 bg-white lg:bg-transparent">
      <div className="flex flex-col justify-center gap-2">
        <h1>Angelika</h1>
        <h1>Chantzopoulou</h1>
      </div>
    </div>
  );
};

export default Navbar;
