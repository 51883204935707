import ProjectData from "./Projects-data";

const Projects = () => {
  return (
    <div className="z-0 flex flex-col justify-end gap-10 p-10 md:gap-20 lg:pt-20">
      {ProjectData.map((project) => (
        <a
          href={project.source}
          target="_blank"
          rel="noopener noreferrer"
          className="flex flex-col self-end gap-5 group lg:w-2/3"
          key={project.index}
        >
          <div className="flex justify-end ">
            <img src={project.photo} alt={project.photoAlt} className="" />
          </div>

          <div className="flex flex-row justify-between">
            <p className="self-center paragraph--project-topic">
              {project.topic}
            </p>
            <p className="self-center paragraph--project-title">
              {project.title}
            </p>
          </div>
        </a>
      ))}
    </div>
  );
};

export default Projects;
